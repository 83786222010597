@import url("https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  font-family: "Arvo", serif;
}

.app {
  background: #0a121d;
}

header {
  margin-bottom: 50px;
}

header .header-top {
  display: flex;
  justify-content: center;
  background: #d9cdcb;
  padding: 10px;
  position: relative;
}

header .header-down {
  display: flex;
  justify-content: flex-end;
  background: #d9cdcb;
}

header .header-down .links {
  display: flex;
  justify-content: flex-end;
  transform: translateY(-10px);
}

.discord,
.twitter {
  margin-right: 20px;
}

.discord svg {
  fill: #5865f2;
  width: 40px;
  height: 40px;
  opacity: 0.5;
  transition: fill 0.3s;
}

.discord:hover svg {
  fill: #3948f4;
}

.twitter svg {
  fill: rgb(29, 155, 240);
  width: 40px;
  height: 40px;
  transition: fill 0.3s;
}

.twitter:hover svg {
  fill: rgb(21, 149, 236);
}

.logo-wrapper {
  background: #d9cdcb;
  padding: 13px;
  position: absolute;
  top: 0;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 7px;
  margin-top: 20px;
}

.logo {
  max-width: 150px;
}

main {
  padding: 30px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.banner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-wrapper > span {
  position: relative;
  background: #fff;
  padding: 2px;
  border-radius: 7px;
}

.banner {
  max-width: 100%;
  height: 350px;
  object-fit: scale-down;
  border-radius: 7px;
}

.coming-soon {
  position: absolute;
  max-width: 250px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.description-wrapper {
  padding: 15px 10px;
  font-size: 20px;
  text-align: center;
  margin: 55px 0px;
  display: flex;
  align-items: center;
}
.description-wrapper .bugbearz {
  max-width: 300px;
  border-radius: 7px;
}
.description-wrapper p {
  color: #fff;
  padding: 15px;
}

.description-wrapper p .me-buy-btn-wrapper {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.magiceden-button {
  display: flex;
  justify-content: center;
  max-width: 150px;
  font-size: 12px;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(123.73deg, #900ce9 22%, #f94e9b 80.67%);
  border-radius: 6px;
  cursor: pointer;
}

.magiceden-button:hover {
  color: #fff;
}

.tool-access-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  color: #fff;
}

.MuiDialog-container * {
  color: #fff !important;
}

.MuiDialog-container .MuiDialogTitle-root {
  background: #be4f3e !important;
}

.tool-access-wrapper .my-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  background: #be4f3e;
  width: 175px;
  height: 38px;
  line-height: 1.75;
  border-radius: 4px;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  text-align: center;
  color: #fff;
}

.tool-access-wrapper .my-btn svg {
  color: #fff;
}

.tool-access-wrapper .my-btn:hover {
  background: #b74c3c;
}

.tool-access-wrapper .my-btn.disabled {
  background: rgba(183, 76, 60, 0.75);
  pointer-events: none;
}

.tool-access-wrapper .checked {
  max-width: 20px;
  margin-left: 5px;
}

.tool-access-wrapper .step-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
  width: 100%;
}

.tool-access-wrapper .main-title {
  margin-bottom: 20px;
}

.tool-access-wrapper .title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tool-access-wrapper .step-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
}

.tool-access-wrapper .step-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
}

.tool-access-wrapper .step-3 .magiceden-button {
  width: 175px;
}

.bugbearz-slider {
  width: 100%;
  max-width: 1000px;
  margin-top: 30px;
}

.bugbearz-slider .img img {
  width: 100%;
}

.bugbearz-slider .name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: #fff;
  color: #b74c3c;
}

.not-enough-bugbearz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-enough-bugbearz .magiceden-button {
  margin-top: 15px;
}

/* .bugbearz-slider .bugbearz-card .bugbearz {
  width: 300px;
  height: 300px;
  background: #000;
} */

.faq-wrapper {
  display: flex;
  justify-content: center;
}

.faq-wrapper .faq-row-wrapper {
  width: 100%;
  padding: 10px;
  border-radius: 7px;
  margin: 10px;
  background: #d9cdcb;
}

.faq-row-wrapper .faq-title {
  padding: 10px;
}

.faq-row-wrapper .faq-row .row-title {
  transition: background 0.5s;
}

.faq-row-wrapper .faq-row .row-title {
  padding: 10px 10px !important;
}

.faq-row-wrapper .faq-row .row-content {
  padding-left: 10px;
}

.faq-row-wrapper .faq-row .row-title:hover,
.faq-row-wrapper .faq-row .row-title.expanded {
  background: #cfbcb9;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #d9cdcb;
  padding: 15px;
}

footer .footer-title {
  font-size: 30px;
}

footer .discord,
footer .twitter {
  margin: 15px;
}

@media only screen and (max-width: 1250px) {
  .banner-wrapper span {
    background: none;
  }
}

@media only screen and (max-width: 800px) {
  .description-wrapper {
    flex-direction: column;
    font-size: 17px;
  }
  .logo {
    max-width: 100px;
  }
}

@media only screen and (max-width: 750px) {
  .banner-wrapper .coming-soon {
    max-width: 215px;
    top: 60px;
  }

  main {
    padding: 0px !important;
  }
  .banner-wrapper span {
    padding: 0px;
  }

  header .links svg {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .banner-wrapper .coming-soon {
    top: 70px;
  }
}

@media only screen and (max-width: 650px) {
  .banner {
    height: 300px;
  }
  .banner-wrapper .coming-soon {
    top: 50px;
  }
  .description-wrapper {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .banner-wrapper .coming-soon {
    max-width: 200px;
    top: 65px;
  }
}

@media only screen and (max-width: 500px) {
  .banner-wrapper .coming-soon {
    max-width: 175px;
    top: 55px;
  }
  .banner {
    height: 250px;
  }
}

/* @media only screen and (max-width: 400px) {
  .banner-wrapper .coming-soon {
    max-width: 125px;
    top: 115px;
  }
} */

/* @media only screen and (max-width: 992px) {
  .banner-wrapper .coming-soon {
    max-width: 200px;
    top: 75px;
  }
} */
